import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  LOCALE_ID,
  Type,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, ActivationEnd, Router, RouterModule } from '@angular/router';
import { AngularModule } from '@atoms/angular';
import { AnnouncementTypeEnum, PermissionsEnum } from '@ev-portals/cp/frontend/shared/api-client';
import { LogoutComponent } from '@ev-portals/cp/frontend/shared/auth/feature-logout';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { AnnouncementService, CartProxyService } from '@ev-portals/cp/frontend/shared/data-access';
import { Environment, MenuName, NavigationService } from '@ev-portals/cp/frontend/shared/util';
import {
  NotificationComponent,
  ScrollTopButtonComponent,
} from '@ev-portals/ev/frontend/ui-library';
import { random } from 'lodash';
import { BadgeModule } from 'primeng/badge';
import { Button } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Ripple } from 'primeng/ripple';
import { filter, map, Observable, of, startWith, switchMap, take } from 'rxjs';

import { CartTourService } from './cart-tour.service';
import { DynamicContentComponent } from './dynamic-content/dynamic-content.component';
import { FooterComponent } from './footer/footer.component';
import { IntroTourService } from './intro-tour.service';

type OverlayCase = 'profile' | 'localization' | 'menu' | 'tutorials';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FooterComponent,
    ScrollTopButtonComponent,
    LogoutComponent,
    NotificationComponent,
    AngularModule,
    DynamicContentComponent,
    MatMenuModule,
    Button,
    MenuModule,
    BadgeModule,
    ListboxModule,
    DividerModule,
    Ripple,
    DropdownModule,
    OverlayPanelModule,
  ],
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements AfterViewInit {
  readonly #authenticationService = inject(AuthenticationService);
  readonly #breakpointObserver = inject(BreakpointObserver);
  readonly #navigationService = inject(NavigationService);
  readonly #cartProxyService = inject(CartProxyService);
  readonly #announcementService = inject(AnnouncementService);
  readonly #introTourService = inject(IntroTourService);
  readonly #environment = inject(Environment);
  readonly #cartTourService = inject(CartTourService);
  readonly #router = inject(Router);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #destroyRef = inject(DestroyRef);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  // User information
  readonly user$ = this.#authenticationService.user$;
  readonly $hasCheckoutPermission = toSignal(this.#authenticationService.hasCheckoutPermission$);
  readonly $permissionMap = this.#authenticationService.$permissionMap;

  readonly $userLoggedIn = toSignal(this.#authenticationService.user$.pipe(map(user => !!user)));

  readonly $announcement = toSignal(this.#announcementService.announcement$, {
    initialValue: null,
  });

  readonly announcementClass: Record<AnnouncementTypeEnum, string> = {
    [AnnouncementTypeEnum.Error]: 'text-xl ai-attention',
    [AnnouncementTypeEnum.Success]: 'text-xl ai-info',
    [AnnouncementTypeEnum.Info]: 'text-xl ai-info',
    [AnnouncementTypeEnum.Warning]: 'text-xl ai-attention',
  };

  readonly announcementColor: Record<AnnouncementTypeEnum, string> = {
    [AnnouncementTypeEnum.Error]: 'rgb(218,49,49)',
    [AnnouncementTypeEnum.Success]: 'rgb(63,178,63)',
    [AnnouncementTypeEnum.Info]: 'white',
    [AnnouncementTypeEnum.Warning]: 'rgb(227,206,98)',
  };

  readonly activeMenuName$ = this.#navigationService.activeMenuName$ as Observable<MenuName>;

  $numberOfAddedCartItems = toSignal(this.#cartProxyService.numberOfAddedCartItems$);

  @ViewChild('wrapperEl') public wrapper?: ElementRef<HTMLElement>;
  @ViewChild('announcementEl') public announcement?: ElementRef<HTMLElement>;
  @ViewChild('imgEl') public img?: ElementRef<HTMLElement>;

  showOverlay = false;
  showAnnouncement = true;

  stickToolbarToTop = false;
  toolbarMinified = false;

  headerComponent?: Type<any>;

  // Labels
  readonly loginLabel = $localize`Login`;
  readonly tutorialsLabel = $localize`Tutorials`;
  readonly introductionTutorialsLabel = $localize`Introduction Tour`;
  readonly cartTutorialsLabel = $localize`Cart Tour`;
  readonly registerLabel = $localize`Register`;

  overlayCase?: OverlayCase;
  readonly isMobileView$ = toSignal(
    this.#breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
      .pipe(map(observer => observer.matches)),
  );

  readonly userMenuItems$: Observable<UserMenuItem[]> = this.user$.pipe(
    map(user => {
      if (!this.$userLoggedIn()) {
        return [];
      }

      const items: UserMenuItem[] = [
        {
          id: 'myProfile',
          title: $localize`My Profile`,
          clickHandler: (): void => {
            void this.#navigationService.navigateToMyProfile();
            this.closeOverlay();
          },
          active$: this.activeMenuName$.pipe(map(name => name === 'user-profile')),
          tourClass: 'profile-menu-link',
          dataCy: 'my-profile-menu',
        },
      ];

      if (user?.permissions?.includes(PermissionsEnum.OrderHistory)) {
        items.push(
          {
            id: 'orderHistory',
            title: $localize`Order History`,
            clickHandler: (): void => {
              void this.#navigationService.navigateToOrderHistory();
              this.closeOverlay();
            },
            active$: this.activeMenuName$.pipe(map(name => name === 'order-history')),
            tourClass: 'order-history-menu-link',
            dataCy: 'order-history-menu',
          },
          {
            id: 'deliveryDocuments',
            title: $localize`Delivery Documents`,
            clickHandler: (): void => {
              void this.#navigationService.navigateToDeliveryDocuments();
              this.closeOverlay();
            },
            active$: this.activeMenuName$.pipe(map(name => name === 'delivery-documents')),
            tourClass: 'delivery-documents-menu-link',
            dataCy: 'delivery-documents-menu',
          },
        );
      }

      if (user?.permissions?.includes(PermissionsEnum.FinancialOverview)) {
        items.push({
          id: 'financialDocs',
          title: $localize`Financial Documents`,
          clickHandler: (): void => {
            void this.#navigationService.navigateToFinancialDocs();
            this.closeOverlay();
          },
          active$: this.activeMenuName$.pipe(map(name => name === 'financial-docs')),
          tourClass: 'financial-docs-menu-link',
          dataCy: 'financial-docs-menu',
        });
      }

      items.push({
        id: 'requestHistory',
        title: $localize`Request History`,
        clickHandler: (): void => {
          void this.#navigationService.navigateToRequestHistory();
          this.closeOverlay();
        },
        active$: this.activeMenuName$.pipe(map(name => name === 'request-history')),
        tourClass: 'request-history-menu-link',
        dataCy: 'request-history-menu',
      });

      if (user?.permissions?.includes(PermissionsEnum.Checkout)) {
        items.push({
          id: 'savedCarts',
          title: $localize`Saved Carts`,
          clickHandler: (): void => {
            void this.#navigationService.navigateToSavedCarts();
            this.closeOverlay();
          },
          active$: this.activeMenuName$.pipe(map(name => name === 'saved-carts')),
          tourClass: 'saved-carts-menu-link',
          dataCy: 'saved-carts-menu',
        });
      }

      items.push({
        id: 'logout',
        title: $localize`Logout`,
        clickHandler: (): void => {
          this.onLogoutAttempt();
          this.closeOverlay();
        },
        active$: of(false),
        dataCy: 'user-logout-menu',
      });

      return items;
    }),
  );

  readonly navigationItems: {
    title: string;
    tourClass: string;
    routerLink: string;
    clickHandler: () => void;
    dataCy: string;
  }[] = [
    {
      title: $localize`Products`,
      tourClass: 'products-menu-link',
      routerLink: '/products',
      clickHandler: () => {
        this.closeOverlay();
      },
      dataCy: 'products-menu',
    },
    {
      title: $localize`Requests`,
      tourClass: 'requests-menu-link',
      routerLink: '/requests/send',
      clickHandler: () => {
        this.closeOverlay();
      },
      dataCy: 'requests-menu',
    },
    {
      title: $localize`Events`,
      tourClass: 'events-menu-link',
      routerLink: '/events',
      clickHandler: () => {
        this.closeOverlay();
      },
      dataCy: 'events-menu',
    },
    // {
    //   title: $localize`Explore`,
    //   tourClass: 'explore-menu-link',
    //   routerLink: '/explore/thickener-explorer',
    //   clickHandler: () => {
    //     this.closeOverlay();
    //   },
    // },
  ];

  // Localization
  activeLocale = inject(LOCALE_ID);
  // Please don´t forget to extend this record for languages that should be shown in the language selection dropdown
  readonly localeTitles: Record<string, string> = {
    'en-GB': $localize`English` + '(GB)',
    'es-MX': $localize`Spanish` + '(MX)',
    'pt-BR': $localize`Portuguese` + '(BR)',
    'zh-CN': $localize`Chinese Simplified` + '(CN)',
    'zh-TW': $localize`Chinese Traditional` + '(TW)',
    'ja-JP': $localize`Japanese` + '(JP)',
    'ko-KR': $localize`Korean` + '(KR)',
    'vi-VN': $localize`Vietnamese` + '(VN)',
  };
  readonly availableLocales = this.#environment.locales
    .filter(locale => !!this.localeTitles[locale])
    .map(locale => ({
      title: this.localeTitles[locale],
      code: locale,
      active: this.activeLocale === locale,
    }));

  readonly images = [
    'https://chemicals.basf.com/api/imaging/focalarea/23x9/2400x/dam/jcr%3A47899a14-b215-39a7-a30e-979751a89b54/start-page-chemicals.jpg',
    'https://plastics-rubber.basf.com/api/imaging/focalarea/23x9/1956x/dam/jcr%3A4d882931-1165-3414-85bf-c55b88eb30e2/scientis%20discussion%204.jpg',
  ];
  readonly image = this.images[random(0, this.images.length - 1)];

  constructor() {
    this.#announcementService.checkAnnouncement();
    this.#router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.closeOverlay());

    effect(() => {
      const announcement = this.$announcement();

      if (announcement) {
        this.#changeDetectorRef.detectChanges();
      }
    });
  }

  closeOverlay() {
    this.showOverlay = false;
    this.overlayCase = undefined;
  }

  ngAfterViewInit(): void {
    this.#introTourService.init(
      () => {
        this.showOverlay = true;
        this.overlayCase = 'profile';
      },
      () => {
        this.showOverlay = false;
        this.overlayCase = undefined;
      },
    );

    this.#introTourService.tour.on(`start`, () => (this.showOverlay = false));
    this.#introTourService.tour.on(`complete`, () => (this.showOverlay = false));
    this.#introTourService.tour.on(`cancel`, () => (this.showOverlay = false));

    this.#router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        startWith(undefined),
        switchMap(() => this.#activatedRoute.firstChild?.data || this.#activatedRoute.data),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe(data => {
        this.headerComponent = data['headerComponent'];
        this.#changeDetectorRef.detectChanges();
      });
  }

  selectLocale(newLocale: string): void {
    this.activeLocale = newLocale;
    this.#navigationService.changeLocale(newLocale);
  }

  toggleOverlay(overlayCase: OverlayCase) {
    if (this.overlayCase === overlayCase && this.showOverlay) {
      this.closeOverlay();
    } else {
      this.overlayCase = overlayCase;
      this.showOverlay = true;
    }
  }

  onLogin(): void {
    this.#router.navigateByUrl('/login');
  }

  onRegister(): void {
    this.#navigationService.setOriginalDestination(this.#router.url);
    this.#navigationService.navigateToRegister();
  }

  /**
   * Updates the toolbar's "stick-to-top" and "minified" state based on scroll position.
   *
   * This function checks the scroll position of the `wrapper` element to determine
   * if the toolbar should be "minified" (i.e., smaller or compressed) and/or "stuck" to the top of the screen.
   *
   * - When the `announcement` element is present, the toolbar will be minified only after the user scrolls
   *   past both the announcement and the image.
   * - When the `announcement` is absent, the toolbar is minified only when the user scrolls past the image.
   * - The toolbar is "stuck" to the top when the user scrolls past the announcement or non-zero scroll position.
   */
  onScroll() {
    const wrapper = this.wrapper?.nativeElement;
    const announcement = this.announcement?.nativeElement;
    const img = this.img?.nativeElement;

    if (wrapper && img && announcement) {
      const announcementHeight = announcement.offsetHeight;
      this.toolbarMinified = wrapper.scrollTop - announcementHeight >= img.offsetHeight;
      this.stickToolbarToTop = wrapper.scrollTop >= announcementHeight;
    } else if (wrapper && img) {
      this.stickToolbarToTop = wrapper.scrollTop != 0;
      this.toolbarMinified = wrapper.scrollTop >= img.offsetHeight;
    }
  }

  onStartCartTour(): void {
    this.showOverlay = false;
    void this.#cartTourService.startTour();
  }

  onStartIntroTour(): void {
    this.showOverlay = false;
    void this.#introTourService.startTour();
  }

  onLogoutAttempt(): void {
    void this.#authenticationService
      .logout()
      .pipe(take(1))
      .subscribe(this.#authenticationService.logoutHandler);
  }
}

export interface UserMenuItem {
  id: string;
  title: string;
  clickHandler: () => void;
  active$: Observable<boolean>;
  tourClass?: string;
  dataCy: string;
}
